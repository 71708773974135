import React from 'react'
import styled from 'styled-components';
import colors from './theme'

const Section = styled.section`
display: flex;
flex-direction:column;
height: 900px;
width: 100vw;
align-items: center;
background-color: ${colors.blue}
`

const NeighborHoodHeading = styled.h1`
color: black;
font-weight: 700;
font-size: 3vw;
font-family: rubik;
width: 25%;
text-align: center;
`

const NeighborhoodSection = (props) => {

  

    return (
        <Section>
            <NeighborHoodHeading>Get to know the neighborhood</NeighborHoodHeading>
            {props.children}
        </Section>
    )
}

export default NeighborhoodSection;