import React from 'react'
import styled from 'styled-components';
import colors from './theme'

const Section = styled.section`
display: flex;
flex-direction: column;
width: 100vw;
align-items: center;
padding: 100px 0px 100px 0px;
background-color: #fff;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23d4f4ef' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
border-bottom: 1px solid lightgray; 
`

const NeighborHoodHeading = styled.h1`
color: black;
font-weight: 700;
font-size: 3vw;
font-family: rubik;
width: 25%;
text-align: center;
`

const ContentContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
font-family: rubik;
`

const ContentSection = styled.div`
width: 48%;
height: 100%;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
@media (min-width: 1200px){
    flex-direction: column;
}
`

const Divider = styled.div`
height: 60%;
border-right: 1px solid lightgray
`

const TextArea = styled.div`
height: 40%;
width: 50%;
word-wrap: break-word;
`

const TextHeading = styled.h3`
font-weight: 700;
font-size: 30px;
text-align: left`

const TextBody = styled.p`
font-weight: 200;
text-align-left;
`

const BuildingSection = (props) => {

  

    return (
        <Section>
            <ContentContainer>
                <ContentSection>
                    <TextArea>
                        <TextHeading>
                            Your Own Space
                        </TextHeading>
                        <TextBody>
                            Enjoy the premium amenities, open spaces and social experiences of a home combined with the ability to retreat to your own private space.
                        </TextBody>
                    </TextArea>
                    
                </ContentSection>
                <Divider/>
                <ContentSection>
                    <TextArea>
                        <TextHeading>
                            No Movers? No Problem!
                        </TextHeading>
                        <TextBody>
                        All spaces at 18th and Linden are turn-key, ready for move-in with everything you need. All we leave to you is making it your own!
                        </TextBody>
                    </TextArea>
                </ContentSection>

            </ContentContainer>
        </Section>
    )
}

export default BuildingSection;